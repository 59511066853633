import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout.component';

import { CardModule, GridModule, ButtonModule } from '@coreui/angular';

@NgModule({
    declarations: [LogoutComponent],
    imports: [CommonModule, CardModule, GridModule, ButtonModule],
    exports: [],
})
export class LogoutModule {}
