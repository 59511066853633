import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { ImportFileModel, ImportpooldataService } from 'src/app/core/services/importpooldata.service';
import { UserService } from 'src/app/core/services/user.service';
import * as moment from 'moment';

interface ImportFileResultModel {
  importStatus: string
  messageImportFile: string
  recordsFail: number
  recordsSuccess: number
  recordsTotal: number
}

@Component({
  selector: 'app-importpooldata',
  templateUrl: './importpooldata.component.html',
  styleUrls: ['./importpooldata.component.scss']
})
export class ImportpooldataComponent implements OnInit {

  role: string = '';
  userName: string = '';
  valueFile: File | undefined;
  fileName: string = '';
  formDataImport: ImportFileModel = {
    fileBase64: '',
    fileName: '',
    fileSize: 0,
    fileType: ''
  }

  resultImport: ImportFileResultModel | undefined;
  resultRecord = '';

  constructor(
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService,
    private importpooldataService: ImportpooldataService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.initialHeader();
    this.getPermission();

  }
  getPermission() {
    const userInfo = this.userService.getProfileUser() as any;
    const userPerm = this.userService.getPermission() as any;

    this.role = userInfo.role;
    this.userName = userInfo.fullName;

  }
  initialHeader() {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Import Pool Data', url: '/importpooldata' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }

  public async changeSelectedFile(event: any) {
    this.alertService.clearAlert();
    if (this.checkFile(event.target.files[0])) {
      this.valueFile = event.target.files[0];
      if (this.valueFile) {
        this.fileName = this.valueFile.name;
        const base64File: any = await this.fileToBase64(this.valueFile);
        this.formDataImport = {
          fileName: this.valueFile.name,
          fileSize: this.valueFile.size,
          fileType: this.valueFile.type,
          fileBase64: base64File
        };
      }

    }
  }

  public clickBrowesFileBtn(id: string): void {
    console.log(id);
    const selectedInputId = id;
    document.getElementById(selectedInputId)?.click();
  }

  checkFile(file: File) {
    const mbSize: number = this.getFileSizeInMb(file?.size || 0);
    if (mbSize > 1) {
      this.alertService.alertError('Invalid file size max 1MB.');
      return false;
    }else if(!file?.type.endsWith('csv') ){
      this.alertService.alertError('Invalid accept file type csv only.');
      return false;
    }else if(!verifyImportDatePartFormat(file?.name) || !file?.name.endsWith('_abopoollist.csv')){
      this.alertService.alertError('Invalid format file name.');
      return false;
    }
    return true;
  }

  public getFileSizeInMb(value: number): number {
    return typeof value == 'number'
      ? parseFloat((value / 1024 / 1024).toFixed(2))
      : 0;
  }

  public get allowedTypeFile(): string {
    return '.csv';
  }

  async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  onClickClear() {
    this.alertService.clearAlert();
    this.fileName = '';
    this.valueFile = undefined;
    this.formDataImport = {
      fileBase64: '',
      fileName: '',
      fileSize: 0,
      fileType: ''
    };
  }

  ngOnDestroy() {
    this.alertService.clearAlert();
  }

  upLoadFile() {
    this.resultImport = undefined;
    this.resultRecord = '';
    this.alertService.clearAlert();
    if (this.isValidFile()) {
      this.spinner.show();
      this.importpooldataService.updateImportPoolData(this.formDataImport).subscribe({
        next: async (res: any) => {
          this.spinner.hide();
          if (res.success) {
            this.alertService.alertSuccess('Import Pool Data Success');
            this.resultImport = {
              importStatus: res?.data?.importStatus,
              messageImportFile: res?.data?.messageImportFile,
              recordsFail: res?.data?.recordsFail,
              recordsSuccess: res?.data?.recordsSuccess,
              recordsTotal: res?.data?.recordsTotal
            }
            this.resultRecord = 'Import. Last update ' + moment().format('YYYY-MM-DD HH:mm:ss');

          }
        },
        error: (error: any) => {
          this.spinner.hide();
          this.alertService.alertError(error?.error?.error?.errorMsg);
        }
      })
    }

  }

  isValidFile() {
    let isVald = true;
    if (!this.formDataImport.fileBase64) {
      isVald = false;
      this.alertService.alertError('Required file');
    }
    return isVald;
  }

}

function verifyImportDatePartFormat(input:string) {
  return /([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(0[0-9]|1[0-9]|2[0-4])([0-5][0-9])([0-5][0-9]))/.test(input);
}