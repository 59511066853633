import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthUser, profileUser, Permission } from 'src/app/core/models/auth/user.model';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private userService: UserService, private alertService: AlertService, private router: Router) {}

    ngOnInit() {
        this.initiateLogin();
    }

    async initiateLogin() {
        const isAuthenticated: boolean = await this.oktaAuth.isAuthenticated();

        // if unauthenticated, redirect to login page
        if (!isAuthenticated) {
            return await this.oktaAuth.signInWithRedirect();
        }

        // get user claims
        const user: UserClaims<CustomUserClaims> = await this.oktaAuth.getUser();
        const nativeid: string = (user['nativeid'] as string) || '';

        // get id token, for exchange token
        const idToken: string = this.oktaAuth.getIdToken() || '';
        this.userService
            .exchangeToken(idToken)
            .pipe(
                tap((res: any) => {
                    const authUser: AuthUser = {
                        access_token: res.jwtToken,
                        username: nativeid,
                    };
                    this.userService.setAuth(authUser);
                }),
                switchMap(() => this.userService.login(nativeid, ''))
            )
            .subscribe({
                next: (v: any) => {
                    // navigate to home page
                    let profileUser: profileUser = {
                        profileName: v.data.profile.profileName,
                        department: v.data.profile.department,
                        fullName: v.data.profile.fullName,
                        needChgPass: v.data.profile.needChgPass,
                        position: v.data.profile.position,
                        profilePic: v.data.profile.profilePic,
                    };
                    let permission = v.data.perm as Array<Permission>;

                    this.userService.setProfileUser(profileUser);
                    this.userService.setPermission(permission);
                    this.router.navigate(['/dashboard']);
                    this.alertService.clearAlert();
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.alertError(error.message);
                    console.error('HTTP Status Code:', error.status);
                    console.error('Error Message:', error.message);
                },
            });
    }
}
