<!-- <c-row>
    <c-col [sm]="12">
        <span>Showing {{getShowingFrom()}} to {{getShowingTo()}} of
            {{pageinfo.totalRecord}} entries</span>
        <c-button-toolbar class="float-end" role="group">
            <c-button-group class="me-2" role="group">
                <button cButton color="primary" [disabled]="pageinfo.page <= 1" (click)="gotoPage(pageinfo.page - 1)">
                    {{'<<'}} </button>
                        <button cButton color="primary" *ngIf="pageNumber[0] > 1" (click)="gotoPage(pageNumber[0] - 1)">
                            {{'...'}}
                        </button>
                        <button cButton color="primary" *ngFor="let n of pageNumber" [disabled]="pageinfo.page == n"
                            (click)="gotoPage(n)">
                            {{n}}
                        </button>
                        <button cButton color="primary" *ngIf="pageNumber[pageNumber.length - 1] != pageinfo.allpage"
                            (click)="gotoPage(pageNumber[pageNumber.length - 1] + 1)">
                            {{'...'}}
                        </button>
                        <button cButton color="primary" [disabled]="pageinfo.page >= pageinfo.allpage"
                            (click)="gotoPage(pageinfo.page + 1)">
                            {{'>>'}}
                        </button>
            </c-button-group>
        </c-button-toolbar>
    </c-col>
</c-row> -->

<c-row style="margin-top: 10px; margin-bottom: 20px">
    <c-col>
        <div class="d-flex align-items-center">
            <c-button-toolbar aria-label="Toolbar with button groups" role="group">
                <c-button-group aria-label="First group" class="me-2 btn-previous-page" role="group">
                    <button class="btn btn-icon" [disabled]="pageinfo.page <= 1" (click)="gotoPage(1)">
                        <svg cIcon class="icon-double-btn" name="cilMediaStepBackward"></svg>
                    </button>
                    <button class="btn btn-icon" [disabled]="pageinfo.page <= 1" (click)="gotoPage(pageinfo.page - 1)">
                        <svg cIcon class="icon-btn" name="cilCaretLeft"></svg>
                    </button>
                </c-button-group>
                <c-button-group aria-label="Second group" class="me-2 btn-select-page" role="group">
                    <button cButton color="dark" variant="outline" size="sm" *ngFor="let n of pageNumber" [disabled]="pageinfo.page == n" (click)="gotoPage(n)">
                        {{ n }}
                    </button>
                </c-button-group>
                <c-button-group aria-label="Third group" class="me-2 btn-next-page" role="group">
                    <button class="btn btn-icon" [disabled]="pageinfo.page >= pageinfo.allpage" (click)="gotoPage(pageinfo.page + 1)">
                        <svg cIcon class="icon-btn" name="cilCaretRight"></svg>
                    </button>
                    <button class="btn btn-icon" [disabled]="pageinfo.page >= pageinfo.allpage" (click)="gotoPage(pageinfo.allpage)">
                        <svg cIcon class="icon-double-btn" name="cilMediaStepForward"></svg>
                    </button>
                </c-button-group>
            </c-button-toolbar>
            <div>Total {{ pageinfo.totalRecord | number : '1.0-0' }} items</div>
            <div style="margin-left: auto; margin-right: 10px">Show</div>
            <c-dropdown>
                <button cButton cDropdownToggle color="dark" variant="outline" size="sm">
                    {{ rowPerPage }}
                </button>
                <ul cDropdownMenu>
                    <li *ngFor="let item of pagingSelectList">
                        <button value="{{ item }}" cDropdownItem (click)="changeRowPerPage($event)">{{ item | number : '1.0-0' }}</button>
                    </li>
                </ul>
            </c-dropdown>
        </div>
    </c-col>
</c-row>
