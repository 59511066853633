import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { observable, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';
import { DemoRole } from '../entity/demorole';
import { HistoryRandomSearch } from '../entity/historyrandom';
import { Util } from '../util/util';

@Injectable({
  providedIn: 'root',
})
export class RetryRandomService {
  constructor(private http: HttpClient) { }

  /** ########## RetryRandom Api ############ **/
  private formatErrors(error: any) {
    return throwError(error);
  }
  // listHistoryRandom(accessToken: string, params: HistoryRandomSearch) {
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json')
  //     .set('Authorization', `Bearer ${accessToken}`);

  //   let data = new HttpParams();

  //   if (
  //     params.ABONumber != 0 &&
  //     params.ABONumber != undefined &&
  //     params.ABOType != '' &&
  //     params.ABOType != undefined
  //   ) {
  //     data = data.append(
  //       params.ABOType == 'forabo' ? 'forAboId' : 'uplineAboId',
  //       params.ABONumber
  //     );
  //   } else if (
  //     params.RandomDateFrom != undefined &&
  //     params.RandomDateTo != undefined
  //   ) {
  //     data = data.append('fromRandomDateTime', encodeURIComponent(params.RandomDateFrom.year + '-' + ('0' + params.RandomDateFrom.month).slice(-2) + '-' + ('0' + params.RandomDateFrom.day).slice(-2) + 'T00:00:00.001+07:00'));
  //     data = data.append('toRandomDateTime', encodeURIComponent(params.RandomDateTo.year + '-' + ('0' + params.RandomDateTo.month).slice(-2) + '-' + ('0' + params.RandomDateTo.day).slice(-2) + 'T23:59:59.999+07:00'));
  //   }
  //   if (params.RandomType.length > 0 && params.RandomType != undefined) {
  //     data = data.append('randomType', JSON.stringify(params.RandomType));
  //   }
  //   if (params.Status.length > 0 && params.Status != undefined) {
  //     data = data.append('randomStatus', JSON.stringify(params.Status));
  //   }
  //   data = data.append('page', 1);
  //   data = data.append('rowPerPage', 9999999);

  //   return this.http
  //     .post(`${environment.api_url}/apibo/call/history-random`, data, {
  //       headers: headers,
  //       params: data,
  //     })
  //     .pipe(catchError(this.formatErrors));
  // }

  // listHistoryRandom(accessToken: string, params: HistoryRandomSearch) {
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json')
  //     .set('Authorization', `Bearer ${accessToken}`);

  //   let data = new HttpParams();

  //   if (params.ABONumber != 0 && params.ABONumber != undefined && params.ABOType != '' && params.ABOType != undefined) {
  //     data = data.append((params.ABOType == 'forabo' ? 'forAboId' : 'uplineAboId'), params.ABONumber.toString());
  //   } else if (params.RandomDateFrom != undefined && params.RandomDateTo != undefined) {
  //     data = data.append('fromRandomDateTime', params.RandomDateFrom.year + '-' + ('0' + params.RandomDateFrom.month).slice(-2) + '-' + ('0' + params.RandomDateFrom.day).slice(-2) + 'T00:00:00.001+07:00');
  //     data = data.append('toRandomDateTime', params.RandomDateTo.year + '-' + ('0' + params.RandomDateTo.month).slice(-2) + '-' + ('0' + params.RandomDateTo.day).slice(-2) + 'T23:59:59.999+07:00');
  //   }
  //   if (params.RandomType.length > 0 && params.RandomType != undefined) {
  //     data = data.append('randomType', JSON.stringify(params.RandomType));
  //   }
  //   if (params.Status.length > 0 && params.Status != undefined) {
  //     data = data.append('randomStatus', JSON.stringify(params.Status));
  //   }
  //   data = data.append('page', '1');
  //   data = data.append('rowPerPage', '9999999');
  //   return this.http
  //     .get(`${environment.api_url}/apibo/call/history-random`, { headers: headers, params: data })
  //     .pipe(catchError(this.formatErrors));
  // }

  listHistoryRandom(accessToken: string, params: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);

    let aboType = (params.ABOType == 'forabo' ? 'forAboId=' : 'uplineAboId=') + params.ABONumber;
    let fromDatetime = 'fromRandomDateTime=' + (params.RandomDateFrom != undefined ? encodeURIComponent(params.RandomDateFrom.year + '-' + ('0' + params.RandomDateFrom.month).slice(-2) + '-' + ('0' + params.RandomDateFrom.day).slice(-2) + 'T00:00:00.001+07:00') : '');
    let toDatetime = '&toRandomDateTime=' + (params.RandomDateTo != undefined ? encodeURIComponent(params.RandomDateTo.year + '-' + ('0' + params.RandomDateTo.month).slice(-2) + '-' + ('0' + params.RandomDateTo.day).slice(-2) + 'T23:59:59.999+07:00') : '');
    let randomType = '&randomType=' + JSON.stringify(params.RandomType);
    let requestStatus = '&randomStatus=' + JSON.stringify(params.RequestStatus);
    let acceptStatus = '&acceptStatus=' + JSON.stringify(params.AcceptStatus);
    let orderNumber = '&orderNumber=' + params.OrderNumber;
    let period = '&period=' + (params.period != undefined ? params.period : '');
    let page = '&page='+(params?.page || '1');
    let rowPerPage = '&rowPerPage='+(params?.rowPerPage || '100');



    let parameter = '';
    if ((params.ABONumber != 0 && params.ABONumber != undefined) || (params.ABOType != '' && params.ABOType != undefined)) {
      parameter = parameter + aboType
    } else if (params.RandomDateFrom != undefined && params.RandomDateTo != undefined) {
      parameter = parameter + fromDatetime
      parameter = parameter + toDatetime
    }
    if (params.RandomType != undefined && params.RandomType.length > 0) {
      parameter = parameter + randomType
    }
    if (params.RequestStatus != undefined && params.RequestStatus.length > 0) {
      parameter = parameter + requestStatus
    }
    if (params.AcceptStatus != undefined && params.AcceptStatus.length > 0) {
      parameter = parameter + acceptStatus
    }
    if (params.OrderNumber != undefined && params.OrderNumber != '' ) {
      parameter = parameter + orderNumber
    }
    if (params.period != undefined) {
      parameter = parameter + period
    }
    parameter = parameter + page
    parameter = parameter + rowPerPage

    return this.http
      .get(`${environment.api_url}/apibo/call/history-random?${parameter}`, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }


  getRandomById(accessToken: string, ramdomById: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);

    return this.http
      .get(
        `${environment.api_url}/apibo/call/random-log-byid?randomId=${ramdomById}`,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }

  random(accessToken: string, param: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);
    const body = {
      randomType: param.randomType,
      randomRetryCnt: param.retryRandom,
      randomId: param.randomId,
      province: param.province,
      postcode: param.postCode,
      requestAboBusinessnature: param.aboBusinessNature,
      requestAboNumber: param.aboNumber,
      requestOrderNumber: param.orderNumber,
      requestOrderPv: param.orderPv,
      requestOrderBv: param.orderBv,
    };

    return this.http
      .post(`${environment.api_url}/apibo/call/randomAbo`, body, {
        headers: headers,
      })
      .pipe(catchError(this.formatErrors));
  }

  newRandom(accessToken: string, param: any, randomType: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);


    if (randomType == '') {

      const body = {
        confirmType: "PAID",
        period: param.period,
        randomId: param.randomId,
        requestAboNumber: param.requestDistNumber,
        requestAboBusinessnature: param.businessNature,
        requestOrderNumber: param.orderNumber,
        requestOrderValue: parseFloat(param.orderValue),
        requestOrderDatetime: param.orderDateTime + ":00.001Z",
        requestOrderPv: Util.isNullOrEmpty(param.orderPv) ? null : Number(param.orderPv),
        requestOrderBv: Util.isNullOrEmpty(param.orderBv) ? null : Number(param.orderBv)
      };

      return this.http
        .post(`${environment.api_url}/apibo/call/orderpaid`, body, {
          headers: headers,
        })
        .pipe(catchError(this.formatErrors));
    } else {

      const body = {
        randomType: randomType,
        randomRetryCnt: 1,
        period: param.period,
        randomId: param.randomId,
        province: param.province,
        postcode: param.postCode,
        requestAboNumber: param.requestDistNumber,
        requestAboBusinessnature: param.businessNature,
        requestOrderNumber: param.orderNumber,
        requestOrderValue: parseFloat(param.orderValue),
        requestOrderDatetime: param.orderDateTime,
        requestOrderPv: Util.isNullOrEmpty(param.orderPv) ? null : Number(param.orderPv),
        requestOrderBv: Util.isNullOrEmpty(param.orderBv) ? null : Number(param.orderBv)
      };


      return this.http
        .post(`${environment.api_url}/apibo/call/randomAbo`, body, {
          headers: headers,
        })
        .pipe(catchError(this.formatErrors));
    }

  }

  sendSMS(accessToken: string, param: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);
    const body = {
      sendMessageType: 'SHORTLINK',
      sendMessage: null,
      sendTo: param.resultAboNumber,
      randomId: param.randomId,
      randomSeqId: param.resultSeqId,
    };

    return this.http
      .post(`${environment.api_url}/apibo/sendsms`, body, {
        headers: headers,
      })
      .pipe(catchError(this.formatErrors));
  }
}
