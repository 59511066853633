import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { HistoryRandom, HistoryRandomSearch, Random } from 'src/app/core/entity/historyrandom';
import { SelectOptionModel } from 'src/app/core/models/common/common.model';
import APIhisRandomSuccess from '../../../mock-data/bo-history random_success.json';
import APICustom from '../../../mock-data/bo-history random_Custom.json';
import { CountCellRenderer } from '../components/count-cell-renderer.component';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';
import { EditBtnCellRenderer } from '../components/edit-button-cell-renderer.component';
import { RandomBtnCellRenderer } from '../components/random-button-cell-renderer.component';
import { HistoryBtnCellRenderer } from '../components/history-button-cell-renderer.component';
import { Router } from '@angular/router';
import { RetryRandomService } from 'src/app/core/services/retryrandom.service';
import { UserService } from 'src/app/core/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { concat } from 'rxjs';
import { PageInfo } from 'src/app/core/models/common/paging-tool.model';

declare var $: any;

interface HistoryRamdomFormModel {
    ramdomId: string | undefined;
    randomType: string | undefined;
    randomStatus: string | undefined;
    randomFor: string | undefined;
    orderNumber: string | undefined;
    paidStatus: string | undefined;
    province: string | undefined;
    postCode: string | undefined;
    flagCntCreditRandom: string | undefined;
    refRequest: string | undefined;
    pureAcceptStatus: string | undefined;
    acceptStatus: string | undefined;
    acceptUpline: string | undefined;
    randomSeqId: string | undefined;
    aboNumber: string | undefined;
    randomFrom: string | undefined;
    pureStatus: string | undefined;
    status: string | undefined;
    sendSMS: string | undefined;
    sendShortLinkChannel: string | undefined;
    shortLink: string | undefined;
    emailRetryRandom: string | undefined;
    emailLastRandom: string | undefined;
    resendSMS: boolean;
    countdown: string | undefined;
}

interface RamdomFormModel {
    aboNumber: string | undefined;
    aboBusinessNature: string | undefined;
    retryRandom: number | undefined;
    retryRandomShow: string | undefined;
    randomFor: string | undefined;
    province: string | undefined;
    postCode: string | undefined;
    randomType: string | undefined;
    randomId: string | undefined;
    orderNumber: string | undefined;
    orderPv: string | undefined;
    orderBv: string | undefined;
    randomSeqId: string | undefined;
    result: string | undefined;
    resultDescription: string | undefined;
    resultAboNumber: string | undefined;
    resultSeqId: string | undefined;
}

@Component({
    selector: 'app-retryrandom',
    templateUrl: './retryrandom.component.html',
    styleUrls: ['./retryrandom.component.scss'],
})
export class RetryrandomComponent implements OnInit {
    modelfrom: NgbDateStruct | null = null;
    modelto: NgbDateStruct | null = null;
    showtab1: boolean = false;
    showtab2: boolean = true;
    showtab3: boolean = true;
    reSendSMSResult: string = '';
    reSendSMSDescription: string = '';
    ramdomTypeRegis: boolean = true;
    ramdomTypeCheckout: boolean = true;
    randomStatusWaiting: boolean = false;
    randomStatusAccept: boolean = false;
    randomStatusNotAccept: boolean = false;
    randomStatusAuto: boolean = false;
    requestStatusNew: boolean = false;
    requestStatusConfirm: boolean = false;
    requestStatusNotConfirm: boolean = false;
    disabledRandomStatus: boolean = false;
    randomId?: any;
    accessToken: string;
    pipe = new DatePipe('en-US');
    showBtnSMS: boolean = false;
    // paginationPageSize = 5;
    resultRecord: any = '';
    resultText: any = '';
    today = new Date();

    /// History Form Model ///
    historyrandomFormModelTab1: HistoryRamdomFormModel = {
        ramdomId: '',
        randomType: '',
        randomStatus: '',
        randomFor: '',
        orderNumber: '',
        paidStatus: '',
        province: '',
        postCode: '',
        flagCntCreditRandom: '',
        refRequest: '',
        pureAcceptStatus: '',
        acceptStatus: '',
        acceptUpline: '',
        randomSeqId: '',
        aboNumber: '',
        randomFrom: '',
        pureStatus: '',
        status: '',
        sendSMS: '',
        sendShortLinkChannel: '',
        shortLink: '',
        emailRetryRandom: '',
        emailLastRandom: '',
        resendSMS: false,
        countdown: '',
    };

    historyrandomFormModelTab2: HistoryRamdomFormModel = {
        ramdomId: '',
        randomType: '',
        randomStatus: '',
        randomFor: '',
        orderNumber: '',
        paidStatus: '',
        province: '',
        postCode: '',
        flagCntCreditRandom: '',
        refRequest: '',
        pureAcceptStatus: '',
        acceptStatus: '',
        acceptUpline: '',
        randomSeqId: '',
        aboNumber: '',
        randomFrom: '',
        pureStatus: '',
        status: '',
        sendSMS: '',
        sendShortLinkChannel: '',
        shortLink: '',
        emailRetryRandom: '',
        emailLastRandom: '',
        resendSMS: false,
        countdown: '',
    };

    historyrandomFormModelTab3: HistoryRamdomFormModel = {
        ramdomId: '',
        randomType: '',
        randomStatus: '',
        randomFor: '',
        orderNumber: '',
        paidStatus: '',
        province: '',
        postCode: '',
        flagCntCreditRandom: '',
        refRequest: '',
        pureAcceptStatus: '',
        acceptStatus: '',
        acceptUpline: '',
        randomSeqId: '',
        aboNumber: '',
        randomFrom: '',
        pureStatus: '',
        status: '',
        sendSMS: '',
        sendShortLinkChannel: '',
        shortLink: '',
        emailRetryRandom: '',
        emailLastRandom: '',
        resendSMS: false,
        countdown: '',
    };
    /// End History Form Model ////

    searchFormModel: HistoryRandomSearch = {
        ABOType: '',
        ABONumber: undefined,
        OrderNumber: '',
        RandomType: ['registration', 'public_checkout'],
        RandomDateFrom: {
            year: this.today.getFullYear(),
            month: this.today.getMonth() + 1,
            day: this.today.getDate(),
        },
        RandomDateTo: {
            year: this.today.getFullYear(),
            month: this.today.getMonth() + 1,
            day: this.today.getDate(),
        },
        RequestStatus: [],
        AcceptStatus: [],
        page: 0,
        rowPerPage: 0,
    };

    randomFormModel: RamdomFormModel = {
        aboNumber: '',
        aboBusinessNature: '',
        retryRandom: undefined,
        retryRandomShow: '',
        randomFor: '',
        province: '',
        postCode: '',
        randomType: '',
        randomId: '',
        orderNumber: '',
        orderPv: '',
        orderBv: '',
        randomSeqId: '',
        result: '',
        resultDescription: '',
        resultAboNumber: '',
        resultSeqId: '',
    };

    ABOTypeOptionList: Array<SelectOptionModel> = [
        {
            value: 'forabo',
            label: 'New ABO',
        },
        {
            value: 'forsponsor',
            label: 'Pool ABO',
        },
    ];

    frameworkComponents = {
        editBtnCellRenderer: EditBtnCellRenderer,
        dateTimeRenderer: DateTimeRenderer,
        randomBtnCellRenderer: RandomBtnCellRenderer,
    };
    searchRowData: Array<any> = [];
    private searchGridApi: any;
    public searchColumnDefs: ColDef[] = [
        {
            headerName: 'Action',
            field: 'randomId',
            cellRendererParams: {
                clicked: (field: any) => {
                    // debugger;
                    this.openHistoryRandom(field.data.randomId);
                    //this.gotoPageRandomHistory(field);
                },
            },
            cellRendererSelector: (params: ICellRendererParams) => {
                return { component: HistoryBtnCellRenderer };
            },
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 130,
        },
        {
            field: '',
            headerName: 'New ABO',
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 300,
            cellRenderer: (params: any) => {
                let aboBusinessNature = '';
                if (params.data.forAboBusinessNature == 'Member') {
                    aboBusinessNature = 'M';
                } else if (params.data.forAboBusinessNature == 'BusinessOwner') {
                    aboBusinessNature = 'A';
                }
                return this.checkNullnUndefied(params.data.forAboNumber) + ' ' + aboBusinessNature + ' ' + this.checkNullnUndefied(params.data.forAboName);
            },
        },
        { field: 'randomType', headerName: 'Random Type', sortable: false, filter: false, suppressMovable: true, width: 140 },
        {
            headerName: 'Random #1',
            field: 'upline[0].aboNumber',
            suppressMovable: true,
            width: 120,
            cellRendererSelector: (params: ICellRendererParams) => {
                return { component: CountCellRenderer };
            },
        },
        {
            headerName: 'Random #1 Date',
            field: 'upline[0].randomDateTime',
            suppressMovable: true,
            width: 150,
            cellRendererSelector: (params: ICellRendererParams) => {
                return { component: CountCellRenderer };
            },
        },
        {
            headerName: 'Random #2',
            field: 'upline[1].aboNumber',
            suppressMovable: true,
            cellRendererParams: {
                clicked: (field: any) => {
                    this.openRandomModal(field, 2);
                    //alert(`${field} was clicked`);
                },
            },
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params?.data?.paidStatus == 'paid' && params?.data?.upline[0]?.acceptStatus == 'notaccept' && params?.data?.upline[1] == undefined) {
                    return {
                        component: RandomBtnCellRenderer,
                    };
                } else if (params?.data?.paidStatus == 'paid' && params?.data?.upline[0]?.acceptStatus == 'notaccept') {
                    return { component: CountCellRenderer };
                } else {
                    return undefined;
                }
            },
        },
        {
            headerName: 'Random #2 Date',
            field: 'upline[1].randomDateTime',
            suppressMovable: true,
            cellRendererSelector: (params: ICellRendererParams) => {
                return { component: CountCellRenderer };
            },
        },
        {
            headerName: 'Random #3',
            field: 'upline[2].aboNumber',
            suppressMovable: true,
            cellRendererParams: {
                clicked: (field: any) => {
                    this.openRandomModal(field, 3);
                    //alert(`${field} was clicked`);
                },
            },
            cellRendererSelector: (params: ICellRendererParams) => {
                if (params?.data?.paidStatus == 'paid' && params?.data?.upline[0]?.acceptStatus == 'notaccept' && params?.data?.upline[1] != undefined) {
                    if (
                        (params?.data?.upline[1]?.randomCnt == 2 || params?.data?.upline[1]?.randomCnt == null) &&
                        params?.data?.upline[1]?.acceptStatus == 'notaccept' &&
                        params?.data?.upline[2] == undefined
                    ) {
                        return { component: RandomBtnCellRenderer };
                    } else {
                        if (params?.data?.upline[2] != undefined) {
                            if (params?.data?.upline[2]?.randomCnt == 3) {
                                return { component: CountCellRenderer };
                            } else {
                                return undefined;
                            }
                        }
                        return undefined;
                    }
                }
                if (params?.data?.upline[2] != undefined) {
                    if (params?.data?.upline[2]?.randomCnt == 3) {
                        return { component: CountCellRenderer };
                    } else {
                        return undefined;
                    }
                } else {
                    return undefined;
                }
            },
        },
        {
            headerName: 'Random #3 Date',
            field: 'upline[2].randomDateTime',
            suppressMovable: true,
            cellRendererSelector: (params: ICellRendererParams) => {
                return { component: CountCellRenderer };
            },
        },
        { field: 'randomId', headerName: 'Random Id', sortable: false, filter: false, suppressMovable: true, width: 350 },
    ];

    pageinfo: PageInfo = {
        page: 1,
        rowPerPage: 20,
        totalRecord: 0,
        allpage: 0,
    };

    constructor(
        private router: Router,
        private retryrandomService: RetryRandomService,
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private headerNavigateService: HeaderNavigateService,
        private alertService: AlertService
    ) {
        this.accessToken = userService.getAuthUser().access_token || '';
    }

    ngOnInit(): void {
        this.initialData();
    }

    search() {
        this.spinner.show();
        this.resultRecord = '';
        this.resultText = '';

        this.searchFormModel.page = this.pageinfo.page;
        this.searchFormModel.rowPerPage = this.pageinfo.rowPerPage;

        this.searchGridApi.setRowData(null);
        if (this.validate()) {
            this.retryrandomService.listHistoryRandom(this.accessToken, this.searchFormModel).subscribe({
                next: (res: any) => {
                    this.searchRowData = [];
                    let response = res.data as Array<HistoryRandom>;
                    // console.log(response);
                    //let response = APICustom.data as Array<HistoryRandom>;
                    this.searchRowData.push(...response);
                    this.searchGridApi.setRowData([]);
                    this.searchGridApi.setRowData(this.searchRowData);
                    this.resultRecord =
                        'Result ' + res.pageinfo.totalRecord + ' records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    this.resultText = '';
                    if (res.pageinfo.totalRecord == 0) {
                        this.resultText = ': ไม่พบข้อมูล ';
                    }
                    this.spinner.hide();

                    this.pageinfo.totalRecord = res?.pageinfo?.totalRecord;
                    this.pageinfo.allpage = Math.ceil(res?.pageinfo?.totalRecord / this.pageinfo.rowPerPage);
                },
                error: (e: any) => {
                    this.resultRecord = 'Result 0 records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    this.resultText = ': ไม่สามารถค้นหาข้อมูลได้ ' + e.error.error.errorCode;
                    this.searchGridApi.setRowData(null);
                    this.spinner.hide();
                },
                complete: () => {
                    this.spinner.hide();
                },
            });
        } else {
            this.spinner.hide();
        }
    }

    onSearchGridReady(params: any) {
        this.searchGridApi = params.api; // To access the grids API
    }

    openRandomModal(randomId: string, retryRandom: any) {
        this.spinner.show();
        this.showBtnSMS = false;
        this.randomFormModel.result = '';
        this.randomFormModel.resultDescription = '';
        this.retryrandomService.getRandomById(this.accessToken, randomId).subscribe({
            next: (v: any) => {
                if (v.data.length > 0) {
                    // console.log(v);
                    let response = v.data[v.data.length - 1] as Random;
                    this.randomFormModel.aboNumber = response.aboNumber;
                    this.randomFormModel.aboBusinessNature = response.aboBusinessNature;
                    this.randomFormModel.retryRandom = retryRandom;
                    this.randomFormModel.retryRandomShow = retryRandom == '2' ? '2nd' : '3rd';
                    this.randomFormModel.randomFor = response.aboNumber + ' ' + this.getaboBusinessNature(response.aboBusinessNature) + ' ' + response.aboName;
                    this.randomFormModel.province = response.province;
                    this.randomFormModel.postCode = response.postcode;
                    this.randomFormModel.randomType = response.randomType;
                    this.randomFormModel.randomId = response.randomId;
                    this.randomFormModel.orderNumber = response.order?.orderNumber;
                    this.randomFormModel.orderBv = response.order?.orderBv;
                    this.randomFormModel.orderPv = response.order?.orderPv;
                    this.randomFormModel.randomSeqId = response.random?.upline?.seqId;

                    $('#random_modal').modal('show');
                    this.spinner.hide();
                }
            },
            error: (e) => {
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
                //console.info('complete');
            },
        });
    }

    openHistoryRandom(param: any) {
        this.spinner.show();
        this.retryrandomService.getRandomById(this.accessToken, param).subscribe({
            next: (v: any) => {
                if (v.data.length > 0) {
                    let response = v.data as Array<Random>;
                    // console.log(v.data);
                    if (response.length >= 0) {
                        (this.historyrandomFormModelTab1.ramdomId = this.checkNullnUndefied(response[0].randomId));
                            (this.historyrandomFormModelTab1.randomType = this.checkNullnUndefied(response[0].randomType));
                            (this.historyrandomFormModelTab1.randomStatus = this.checkNullnUndefied(response[0].randomStatus));
                            (this.historyrandomFormModelTab1.randomFor =
                                this.checkNullnUndefied(response[0].aboNumber) +
                                ' ' +
                                this.getaboBusinessNature(response[0].aboBusinessNature) +
                                ' ' +
                                this.checkNullnUndefied(response[0].aboName));
                        (this.historyrandomFormModelTab1.orderNumber =
                            this.checkNullnUndefied(response[0].order?.orderNumber) +
                            ' : ' +
                            this.checkNullnUndefied(this.pipe.transform(response[0].order?.orderDateTime, 'dd/MM/yyyy HH:mm')));
                            (this.historyrandomFormModelTab1.paidStatus = this.checkNullnUndefied(
                                response[0].order?.paidStatus +
                                    ' : ' +
                                    this.checkNullnUndefied(this.pipe.transform(response[0].order?.paidDateTime, 'dd/MM/yyyy HH:mm'))
                            ));
                            (this.historyrandomFormModelTab1.province = this.checkNullnUndefied(response[0].province));
                            (this.historyrandomFormModelTab1.postCode = this.checkNullnUndefied(response[0].postcode));
                            (this.historyrandomFormModelTab1.flagCntCreditRandom = this.checkNullnUndefied(response[0].flagCntCreditRandom));
                            (this.historyrandomFormModelTab1.refRequest =
                                this.checkNullnUndefied(response[0].refRequestId) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[0].requestDatetime, 'dd/MM/yyyy HH:mm')));
                            (this.historyrandomFormModelTab1.acceptStatus =
                                this.checkNullnUndefied(response[0].accept?.acceptStatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[0].accept?.acceptDateTime, 'dd/MM/yyyy HH:mm')));
                            (this.historyrandomFormModelTab1.acceptUpline =
                                this.checkNullnUndefied(response[0].accept?.uplineNumber) + '  ' + this.checkNullnUndefied(response[0].accept?.uplineName));
                            (this.historyrandomFormModelTab1.randomSeqId = this.checkNullnUndefied(response[0].random?.upline?.seqId));
                            (this.historyrandomFormModelTab1.aboNumber = this.checkNullnUndefied(response[0].random?.upline?.aboNumber));
                            (this.historyrandomFormModelTab1.randomFrom = this.checkNullnUndefied(response[0].random?.upline?.randomFrom));
                            this.historyrandomFormModelTab1.sendShortLinkChannel = this.checkNullnUndefied(response[0].random?.upline?.sendShortLinkChannel) == ''?"SMS":response[0].random?.upline?.sendShortLinkChannel;
                            if(this.historyrandomFormModelTab1.sendShortLinkChannel == "SMS"){
                                (this.historyrandomFormModelTab1.sendSMS =
                                    this.checkNullnUndefied(response[0].random?.upline?.sendSMSstatus) +
                                    ' : ' +
                                    this.checkNullnUndefied(this.pipe.transform(response[0].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')));
                                
                            }else{

                                let sendLine = "";
                                if(response[0].random?.upline?.sendLineResultList != undefined && response[0].random?.upline?.sendLineResultList != null){
                                    for(let i=0;i<response[0].random?.upline?.sendLineResultList.length;i++){
                                        let data = response[0].random?.upline?.sendLineResultList[i];
                                        // 123456 success : 29/05/2024 18:03
                                        if(sendLine != ""){
                                            sendLine += "<br/>";
                                        }

                                        sendLine += `${data.sendPartyId} ${data.sendLinestatus} ${this.pipe.transform(response[0].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')}`;
                                    }
                                }
                                (this.historyrandomFormModelTab1.sendSMS = sendLine);
                                    
                            }
                            (this.historyrandomFormModelTab1.status =
                                this.checkNullnUndefied(response[0].random?.upline?.acceptStatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[0].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm')));
                            (this.historyrandomFormModelTab1.shortLink = this.checkNullnUndefied(response[0].random?.upline?.acceptShortLinkUrl));
                        this.historyrandomFormModelTab1.resendSMS =
                            response[0].random?.upline?.sendSMSstatus == '' || response[0].random?.upline?.sendSMSstatus == null ? false : true;
                        this.historyrandomFormModelTab1.pureAcceptStatus = response[0].accept?.acceptStatus;
                        this.historyrandomFormModelTab1.pureStatus = response[0].random?.upline?.acceptStatus;
                        this.historyrandomFormModelTab1.countdown =
                            this.checkNullnUndefied(this.pipe.transform(response[0].random?.upline?.startCountdown, 'dd/MM/yyyy HH:mm')) +
                            ' - ' +
                            this.checkNullnUndefied(this.pipe.transform(response[0].random?.upline?.endCountdown, 'dd/MM/yyyy HH:mm'));
                    }

                    if (response.length > 1) {
                        this.showtab2 = false;
                        (this.historyrandomFormModelTab2.randomSeqId = this.checkNullnUndefied(response[1].random?.upline?.seqId));
                            (this.historyrandomFormModelTab2.aboNumber = this.checkNullnUndefied(response[1].random?.upline?.aboNumber));
                            (this.historyrandomFormModelTab2.randomFrom = this.checkNullnUndefied(response[1].random?.upline?.randomFrom));
                            this.historyrandomFormModelTab2.sendShortLinkChannel = this.checkNullnUndefied(response[1].random?.upline?.sendShortLinkChannel) == ''?"SMS":response[1].random?.upline?.sendShortLinkChannel;
                            if(this.historyrandomFormModelTab2.sendShortLinkChannel == "SMS"){
                                (this.historyrandomFormModelTab2.sendSMS =
                                    this.checkNullnUndefied(response[1].random?.upline?.sendSMSstatus) +
                                    ' : ' +
                                    this.checkNullnUndefied(this.pipe.transform(response[1].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')));
                                
                            }else{

                                let sendLine = "";
                                if(response[1].random?.upline?.sendLineResultList != undefined && response[1].random?.upline?.sendLineResultList != null){
                                    for(let i=0;i<response[1].random?.upline?.sendLineResultList.length;i++){
                                        let data = response[1].random?.upline?.sendLineResultList[i];
                                        // 123456 success : 29/05/2024 18:03
                                        if(sendLine != ""){
                                            sendLine += "<br/>";
                                        }

                                        sendLine += `${data.sendPartyId} ${data.sendLinestatus} ${this.pipe.transform(response[1].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')}`;
                                    }
                                }
                                (this.historyrandomFormModelTab2.sendSMS = sendLine);
                                    
                            }
                            /*(this.historyrandomFormModelTab2.sendSMS =
                                this.checkNullnUndefied(response[1].random?.upline?.sendSMSstatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[1].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')));
                            */(this.historyrandomFormModelTab2.status =
                                this.checkNullnUndefied(response[1].random?.upline?.acceptStatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[1].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm')));
                            (this.historyrandomFormModelTab2.shortLink = this.checkNullnUndefied(response[1].random?.upline?.acceptShortLinkUrl));
                        this.historyrandomFormModelTab2.resendSMS =
                            response[1].random?.upline?.sendSMSstatus == '' || response[1].random?.upline?.sendSMSstatus == null ? false : true;
                        this.historyrandomFormModelTab2.countdown =
                            this.checkNullnUndefied(this.pipe.transform(response[1].random?.upline?.startCountdown, 'dd/MM/yyyy HH:mm')) +
                            ' - ' +
                            this.checkNullnUndefied(this.pipe.transform(response[1].random?.upline?.endCountdown, 'dd/MM/yyyy HH:mm'));
                    } else {
                        this.showtab2 = true;
                        this.historyrandomFormModelTab2.randomSeqId = '';
                        this.historyrandomFormModelTab2.aboNumber = '';
                        this.historyrandomFormModelTab2.randomFrom = '';
                        this.historyrandomFormModelTab2.sendSMS = '';
                        this.historyrandomFormModelTab2.status = '';
                        this.historyrandomFormModelTab2.shortLink = '';
                        this.historyrandomFormModelTab2.resendSMS = true;
                        this.historyrandomFormModelTab2.countdown = '';
                        
                    }

                    if (response.length > 2) {
                        this.showtab3 = false;
                        (this.historyrandomFormModelTab3.randomSeqId = this.checkNullnUndefied(response[2].random?.upline?.seqId));
                            (this.historyrandomFormModelTab3.aboNumber = this.checkNullnUndefied(response[2].random?.upline?.aboNumber));
                            (this.historyrandomFormModelTab3.randomFrom = this.checkNullnUndefied(response[2].random?.upline?.randomFrom));
                            this.historyrandomFormModelTab3.sendShortLinkChannel = this.checkNullnUndefied(response[2].random?.upline?.sendShortLinkChannel) == ''?"SMS":response[2].random?.upline?.sendShortLinkChannel;
                            if(this.historyrandomFormModelTab3.sendShortLinkChannel == "SMS"){
                                (this.historyrandomFormModelTab3.sendSMS =
                                    this.checkNullnUndefied(response[2].random?.upline?.sendSMSstatus) +
                                    ' : ' +
                                    this.checkNullnUndefied(this.pipe.transform(response[2].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')));
                                
                            }else{

                                let sendLine = "";
                                if(response[2].random?.upline?.sendLineResultList != undefined && response[2].random?.upline?.sendLineResultList != null){
                                    for(let i=0;i<response[2].random?.upline?.sendLineResultList.length;i++){
                                        let data = response[2].random?.upline?.sendLineResultList[i];
                                        // 123456 success : 29/05/2024 18:03
                                        if(sendLine != ""){
                                            sendLine += "<br/>";
                                        }

                                        sendLine += `${data.sendPartyId} ${data.sendLinestatus} ${this.pipe.transform(response[2].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')}`;
                                    }
                                }
                                (this.historyrandomFormModelTab3.sendSMS = sendLine);
                                    
                            }
                            /*(this.historyrandomFormModelTab3.sendSMS =
                                this.checkNullnUndefied(response[2].random?.upline?.sendSMSstatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[2].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')));
                            */
                            (this.historyrandomFormModelTab3.status =
                                this.checkNullnUndefied(response[2].random?.upline?.acceptStatus) +
                                ' : ' +
                                this.checkNullnUndefied(this.pipe.transform(response[2].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm'))),
                            (this.historyrandomFormModelTab3.shortLink = this.checkNullnUndefied(response[2].random?.upline?.acceptShortLinkUrl));
                        this.historyrandomFormModelTab3.resendSMS =
                            response[2].random?.upline?.sendSMSstatus == '' || response[2].random?.upline?.sendSMSstatus == null ? false : true;
                        this.historyrandomFormModelTab3.emailRetryRandom = this.checkNullnUndefied(response[2].random?.upline?.sendMailManualstatus);
                        this.historyrandomFormModelTab3.emailLastRandom = this.checkNullnUndefied(response[2].random?.upline?.sendMailLastRandomstatus);
                        this.historyrandomFormModelTab3.countdown =
                            this.checkNullnUndefied(this.pipe.transform(response[2].random?.upline?.startCountdown, 'dd/MM/yyyy HH:mm')) +
                            ' - ' +
                            this.checkNullnUndefied(this.pipe.transform(response[2].random?.upline?.endCountdown, 'dd/MM/yyyy HH:mm'));
                    } else {
                        this.showtab3 = true;
                        this.historyrandomFormModelTab3.randomSeqId = '';
                        this.historyrandomFormModelTab3.aboNumber = '';
                        this.historyrandomFormModelTab3.randomFrom = '';
                        this.historyrandomFormModelTab3.sendSMS = '';
                        this.historyrandomFormModelTab3.status = '';
                        this.historyrandomFormModelTab3.shortLink = '';
                        this.historyrandomFormModelTab3.resendSMS = true;
                        this.historyrandomFormModelTab3.countdown = '';
                    }
                    $('#history_modal').modal('show');
                    this.spinner.hide();
                }
            },
            error: (e) => {
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
                //console.info('complete');
            },
        });
    }

    gotoPageRandomHistory(param: any) {
        // const navigationDetails: string[] = ['/historyrandom'];
        // this.router.navigate(navigationDetails, {
        //   queryParams: { randomId: param },
        // });

        const host: string = location.origin;
        const url: string =
            host +
            '/#/' +
            String(
                this.router.createUrlTree(['/historyrandom'], {
                    queryParams: { randomId: param },
                })
            );
        window.open(url, '_blank');
    }

    onCheckboxRequestStatusChange(name: any, param: any) {
        if (param.target.checked) {
            if (this.searchFormModel.RequestStatus.indexOf(name) < 0) {
                this.searchFormModel.RequestStatus.push(name);
            }
        } else {
            const index = this.searchFormModel.RequestStatus.indexOf(name);
            if (index > -1) {
                this.searchFormModel.RequestStatus.splice(index, 1);
            }
            // if (name == 'waiting') {
            //     if (!this.requestStatusConfirm && !this.randomStatusWaiting && index > -1) {
            //         this.searchFormModel.Status.splice(index, 1);
            //     }
            // } else if (index > -1) {
            //     this.searchFormModel.Status.splice(index, 1);
            // }
        }
        // console.log(this.searchFormModel.RequestStatus);
    }

    onCheckboxAcceptStatusChange(name: any, param: any) {
        if (param.target.checked) {
            if (this.searchFormModel.AcceptStatus.indexOf(name) < 0) {
                this.searchFormModel.AcceptStatus.push(name);
            }
        } else {
            const index = this.searchFormModel.AcceptStatus.indexOf(name);
            if (index > -1) {
                this.searchFormModel.AcceptStatus.splice(index, 1);
            }
            // if (name == 'waiting') {
            //     if (!this.requestStatusConfirm && !this.randomStatusWaiting && index > -1) {
            //         this.searchFormModel.Status.splice(index, 1);
            //     }
            // } else if (index > -1) {
            //     this.searchFormModel.Status.splice(index, 1);
            // }
        }
        // console.log(this.searchFormModel.AcceptStatus);
    }

    onCheckboxTypeChange(name: any, param: any) {
        if (param.target.checked) {
            this.searchFormModel.RandomType.push(name);
        } else {
            const index = this.searchFormModel.RandomType.indexOf(name);
            if (index > -1) {
                this.searchFormModel.RandomType.splice(index, 1);
            }
        }
        // console.log(this.searchFormModel.RandomType);

        if (this.searchFormModel.RandomType.indexOf('registration') > -1) {
            this.disabledRandomStatus = false;
        } else {
            this.disabledRandomStatus = true;
            this.randomStatusWaiting = false;
            this.randomStatusAccept = false;
            this.randomStatusNotAccept = false;
            this.randomStatusAuto = false;
        }
    }

    goToRandom() {
        this.spinner.show();
        this.retryrandomService.random(this.accessToken, this.randomFormModel).subscribe({
            next: (v: any) => {
                if (v.success) {
                    this.showBtnSMS = true;
                    this.randomFormModel.result = 'Success';
                    this.randomFormModel.resultDescription = v.data.aboNumber + ' ' + v.data.aboName;
                    this.randomFormModel.resultAboNumber = v.data.aboNumber;
                    this.randomFormModel.resultSeqId = v.randomSeqId;
                }
                this.spinner.hide();
            },
            error: (e) => {
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
                //console.info('complete');
            },
        });
    }

    sendSMS() {
        this.spinner.show();
        this.retryrandomService.sendSMS(this.accessToken, this.randomFormModel).subscribe({
            next: (v: any) => {
                if (v.success) {
                    this.randomFormModel.result = 'Send SMS Success';
                    this.randomFormModel.resultDescription = 'ทำการส่ง sms ไปยังอัพไลน์';
                } else {
                    this.randomFormModel.result = 'Send SMS Failed';
                    this.randomFormModel.resultDescription = v.requestId + ':' + v.error.errorCode;
                }
                this.spinner.hide();
            },
            error: (e) => {
                this.randomFormModel.result = 'Send SMS Failed';
                this.randomFormModel.resultDescription = e.error.requestId + ':' + e.error.error.errorCode;
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
                //console.info('complete');
            },
        });
    }

    reSendSMS(ramdomId: any, aboNumber: any, seqId: any) {
        this.spinner.show();
        let data = {
            resultAboNumber: aboNumber,
            randomId: ramdomId,
            resultSeqId: seqId,
        };
        this.retryrandomService.sendSMS(this.accessToken, data).subscribe({
            next: (v: any) => {
                if (v.success) {
                    this.reSendSMSResult = 'Send SMS Success';
                    this.reSendSMSDescription = 'ทำการส่ง sms ไปยังอัพไลน์';
                } else {
                    this.reSendSMSResult = 'Send SMS Failed';
                    this.reSendSMSDescription = v.requestId + ':' + v.error.errorCode;
                }
                this.spinner.hide();
            },
            error: (e) => {
                this.reSendSMSResult = 'Send SMS Failed';
                this.reSendSMSDescription = e.error.requestId + ':' + e.error.error.errorCode;
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
                //console.info('complete');
            },
        });
    }

    clear() {
        (this.searchFormModel.ABOType = ''),
            (this.searchFormModel.ABONumber = undefined),
            (this.searchFormModel.OrderNumber = ''),
            (this.searchFormModel.RandomType = ['registration', 'public_checkout']),
            (this.searchFormModel.RandomDateFrom = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() }),
            (this.searchFormModel.RandomDateTo = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() }),
            (this.searchFormModel.AcceptStatus = []),
            (this.searchFormModel.RequestStatus = []);
        this.searchGridApi.setRowData(null);
        this.randomStatusWaiting = false;
        this.randomStatusAccept = false;
        this.randomStatusNotAccept = false;
        this.randomStatusAuto = false;
        this.requestStatusNew = false;
        this.requestStatusConfirm = false;
        this.requestStatusNotConfirm = false;
        this.resultRecord = '';
        this.resultText = '';
        this.ramdomTypeRegis = true;
        this.ramdomTypeCheckout = true;
        this.disabledRandomStatus = false;
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Retry Random', url: '/retryrandom' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    checkNullnUndefied(param: any) {
        if (param == null || param == undefined) {
            return '';
        } else {
            return param;
        }
    }

    validate() {
        this.alertService.clearAlert();
        let valid = true;
        if (this.searchFormModel.ABOType != '' && (this.searchFormModel.ABONumber?.toString() == '' || this.searchFormModel.ABONumber == undefined)) {
            this.alertService.alertError('Please Input ABO Number');
            valid = false;
        }
        return valid;
    }

    getaboBusinessNature(v: any) {
        if (v == 'Member') {
            return 'M';
        } else if (v == 'BusinessOwner') {
            return 'A';
        } else if (v == 'GuestCustomer') {
            return 'C';
        } else {
            return '';
        }
    }

    /**
     * callback event for change page for paging
     * @param page
     */
    async gotoPage(page: number) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.pageinfo.page = page;
            this.search();
        }
    }

    /**
     * callback event for paging : change row per page
     * @param rowPerPage
     */
    changeRowPerpage(rowPerPage: number) {
        this.pageinfo.page = 1;
        this.pageinfo.rowPerPage = rowPerPage;
        this.search();
    }
}
