import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { Apilog } from 'src/app/core/entity/apilog';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { ApilogService } from 'src/app/core/services/apilog.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { UserService } from 'src/app/core/services/user.service';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';
import { DetailBtnCellRenderer } from '../components/detail-button-cell-renderer.component';
import { PageInfo } from 'src/app/core/models/common/paging-tool.model';

declare var $: any;

interface searchFormModel {
  period: string | null;
  dateFrom: string | undefined;
  dateTo: string | undefined;
  clientId: string | undefined;
  apiPath: string | undefined;
  requestId: string | undefined;
  status: any[];
  page : number;
  rowPerPage : number;
}

@Component({
  selector: 'app-apilog',
  templateUrl: './apilog.component.html',
  styleUrls: ['./apilog.component.scss']
})
export class ApilogComponent implements OnInit {
  accessToken: string;
  resultRecord: any = '';
  resultText: any = '';
  // paginationPageSize = 20;
  pipe = new DatePipe('en-US');
  today = new Date();
  searchFormModel: searchFormModel = {
    period: this.pipe.transform(this.today, 'yyyy-MM'),
    dateFrom: '',
    dateTo: '',
    clientId: '',
    apiPath: '',
    requestId: '',
    status: ['success', 'failed'],
    page: 0,
    rowPerPage: 0
  }

  apiLogModalData: Apilog = {};

  dateList:string[]; 

  searchRowData: Array<any> = [];
  searcHishRowData: Array<any> = [];

      pageinfo: PageInfo = {
          page: 1,
          rowPerPage: 20,
          totalRecord: 0,
          allpage: 0,
      };
      
  private searchGridApi: any;
  private searchHisGridApi: any;
  public searchColumnDefs: ColDef[] = [
    {
      headerName: 'Action',
      field: 'uId',
      cellRendererParams: {
        clicked: (field: any) => {
          this.openDetailApiLog(field);
        },
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        return { component: DetailBtnCellRenderer };
      },
      sortable: false,
      filter: false,
      suppressMovable: true,
      width: 130,
    },
    { 
      field: 'requestDateTime', 
      headerName: 'Request Datetime', 
      sortable: false, 
      filter: false, 
      suppressMovable: true,
      cellRendererSelector: (params: ICellRendererParams) => {
        return { component: DateTimeRenderer };
      }
    },
    { field: 'apiPath', headerName: 'API Path', sortable: false, filter: false, suppressMovable: true },
    { field: 'clientId', headerName: 'Client ID', sortable: false, filter: false, suppressMovable: true },
    { 
      field: 'responseDateTime', 
      headerName: 'Response Datetime', 
      sortable: false, 
      filter: false, 
      suppressMovable: true, 
      cellRendererSelector: (params: ICellRendererParams) => {
        return { component: DateTimeRenderer };
      }
    },
    { field: 'status', headerName: 'Status', sortable: false, filter: false, suppressMovable: true },
    { field: 'responseStatusCode', headerName: 'Http Code', sortable: false, filter: false, suppressMovable: true },
    { field: 'errorCode', headerName: 'Error Code', sortable: false, filter: false, suppressMovable: true },
    { field: 'requestId', headerName: 'Request ID', sortable: false, filter: false, suppressMovable: true, width: 350,},
  ];

  constructor(
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService,
    private spinner: NgxSpinnerService,
    private apiLogService: ApilogService
  ) {
    this.accessToken = this.userService.getAuthUser().access_token || '';
    this.dateList = this.getTotalsdateOfMonth(this.searchFormModel.period || '');
   }

  ngOnInit() {
    this.initialData();
  }

  initialData() {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Api Log', url: '/apilog' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }

  search() {

    if(this.searchFormModel.dateFrom && this.searchFormModel.dateTo){
      if(Number(this.searchFormModel.dateFrom) > Number(this.searchFormModel.dateTo)){
        console.log("invalid date from-to");
        return;
      }
    }else{
      if(this.searchFormModel.dateFrom || this.searchFormModel.dateTo){
        console.log("require date from-to");
        return;
      }
    }
    
    this.spinner.show();

    this.searchFormModel.page = this.pageinfo.page;
    this.searchFormModel.rowPerPage = this.pageinfo.rowPerPage;

    this.apiLogService
      .listHistoryApi(this.accessToken, this.searchFormModel)
      .subscribe({
        next: (res: any) => {
          this.searchRowData = [];
          let response = res.data as Array<Apilog>;
          this.searchRowData.push(...response);
          this.searchGridApi.setRowData([]);
          this.searchGridApi.setRowData(this.searchRowData);
          this.resultRecord = 'Result ' + res.pageinfo.totalRecord + ' records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
          this.resultText = '';
          if (res.pageinfo.totalRecord == 0) {
            this.resultText = ': ไม่พบข้อมูล ';
          }
          
          this.pageinfo.totalRecord = res?.pageinfo?.totalRecord;
          this.pageinfo.allpage = Math.ceil(res?.pageinfo?.totalRecord / this.pageinfo.rowPerPage);
      
          this.spinner.hide();
        },
        error: (e: any) => {
          this.resultRecord = 'Result 0 records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'); this.resultText = ': ไม่สามารถค้นหาข้อมูลได้ ' + e.error.error.errorCode;
          this.searchGridApi.setRowData(null);
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      })
  }

  openDetailApiLog(param: any) {
    this.spinner.show();
    this.apiLogModalData = this.searchRowData.find(e => e.uId == param);
    this.apiLogModalData.requestDateTime = this.pipe.transform(this.apiLogModalData.requestDateTime, 'dd-MM-yyyy HH:mm:ss.SSS') || '';
    this.apiLogModalData.responseDateTime = this.pipe.transform(this.apiLogModalData.responseDateTime, 'dd-MM-yyyy HH:mm:ss.SSS') || '';
    $('#detail_modal').modal('show');
    this.spinner.hide();
  }

  onSearchGridReady(params: any) {
    this.searchGridApi = params.api; // To access the grids API
  }

  changePeriod(event: any){
    this.dateList = this.getTotalsdateOfMonth(event.target.value);
    this.searchFormModel.dateFrom = '';
    this.searchFormModel.dateTo = '';
  }

  getTotalsdateOfMonth(period: string): string[] {
    console.log(period);
    let dateList = [];
    let lastDateOnMonth = new Date(new Date(period).getFullYear(), new Date(period).getMonth() + 1, 0).getDate();
    for (let i = 0; i < lastDateOnMonth; i++) {
      dateList.push((i+1).toString());
    }
    return dateList;
  }

  onCheckboxTypeChange(param: any) {
    if (param.target.checked) {
      this.searchFormModel.status.push(param.target.value);
    } else {
      const index = this.searchFormModel.status.indexOf(param.target.value);
      if (index > -1) {
        this.searchFormModel.status.splice(index, 1);
      }
    }
  }

  clear() {
    this.searchFormModel.period = this.pipe.transform(this.today, 'yyyy-MM');
    this.searchFormModel.dateFrom = '';
    this.searchFormModel.dateTo = '';
    this.searchFormModel.clientId = '';
    this.searchFormModel.apiPath = '';
    this.searchFormModel.requestId = '';
    this.searchFormModel.status = ['success', 'failed'];
    $('#search_type_success').prop('checked', true);
    $('#search_type_fail').prop('checked', true);
  }

      /**
     * callback event for change page for paging
     * @param page
     */
      async gotoPage(page: number) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.pageinfo.page = page;
            this.search();
        }
    }

    /**
     * callback event for paging : change row per page
     * @param rowPerPage
     */
    changeRowPerpage(rowPerPage: number) {
        this.pageinfo.page = 1;
        this.pageinfo.rowPerPage = rowPerPage;
        this.search();
    }

}
