import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { UserService } from 'src/app/core/services/user.service';
import { PoolDataService } from 'src/app/core/services/pooldata.service.';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { HistoryBtnCellRenderer } from '../components/history-button-cell-renderer.component';
import { DatePipe } from '@angular/common';
import { poolData } from 'src/app/core/entity/pooldata';
import { RetryRandomService } from 'src/app/core/services/retryrandom.service';
import { HistoryRandom } from 'src/app/core/entity/historyrandom';
import { PageInfo } from 'src/app/core/models/common/paging-tool.model';

declare var $: any;
interface searchFormModel {
    period: string | undefined;
    poolABO: string | undefined;
    postCode: string | undefined;
    province: string | undefined;
    randomType: any[];
    result: string | undefined;
    resultDescription: string | undefined;
    page: number;
    rowPerPage: number;
}

@Component({
    selector: 'app-pooldata',
    templateUrl: './pooldata.component.html',
    styleUrls: ['./pooldata.component.scss'],
})
export class PooldataComponent implements OnInit {
    accessToken: string;
    resultRecord: any = '';
    resultText: any = '';
    paginationPageSize = 100;
    pipe = new DatePipe('en-US');
    today = new Date();
    ramdomTypeRegis: boolean = true;
    ramdomTypeCheckout: boolean = true;
    searchFormModel: searchFormModel = {
        period: this.today.getFullYear().toString() + '-' + ('0' + (this.today.getMonth() + 1)).slice(-2),
        poolABO: '',
        province: '',
        postCode: '',
        randomType: ['registration', 'public_checkout'],
        result: '',
        resultDescription: '',
        page: 0,
        rowPerPage: 0,
    };

    historyRandomModel: HistoryRandom = {
        forAboNumber: '',
        forOrderBv: '',
        forOrderNumber: '',
        forOrderPv: '',
        paidDateTime: undefined,
        paidStatus: '',
        postcode: '',
        province: '',
        randomId: '',
        randomType: '',
        upline: [],
    };

    searchRowData: Array<any> = [];
    searcHishRowData: Array<any> = [];
    private searchGridApi: any;
    private searchHisGridApi: any;
    public searchColumnDefs: ColDef[] = [
        {
            headerName: 'Action',
            field: '',
            cellRendererParams: {
                clicked: (field: any) => {
                    this.openHistoryRandom(field.data.distNumber, field.data.postCode, field.data.stateCode);
                },
            },
            cellRendererSelector: (params: ICellRendererParams) => {
                let regis = params?.data?.random?.filter((x: any) => x?.randomType == 'registration');
                let check = params?.data?.random?.filter((x: any) => x?.randomType == 'public_checkout');

                let count = 0;
                if (regis != undefined && regis?.length > 0) {
                    count += regis[0]?.cntPostcode + regis[0]?.cntStatecode;
                }
                if (check != undefined && check?.length > 0) {
                    count += check[0]?.cntPostcode + check[0]?.cntStatecode;
                }
                if (count == 0) {
                    return {};
                }
                return { component: HistoryBtnCellRenderer };
            },
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 130,
        },
        { field: 'distNumber', headerName: 'Pool ABO', sortable: false, filter: false, suppressMovable: true },
        { field: 'postCode', headerName: 'Post Code', sortable: false, filter: false, suppressMovable: true },
        { field: 'stateCode', headerName: 'Province', sortable: false, filter: false, suppressMovable: true },
        {
            field: '',
            headerName: 'Credit - Registration',
            sortable: false,
            filter: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                let regis = params?.data?.random?.filter((x: any) => x?.randomType == 'registration');
                if (regis != undefined && regis?.length > 0) {
                    return regis[0]?.cntPostcode + regis[0]?.cntStatecode;
                } else {
                    return 0;
                }
            },
        },
        {
            field: '',
            headerName: 'Credit - PublicCheckout',
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 300,
            cellRenderer: (params: any) => {
                let check = params?.data?.random?.filter((x: any) => x?.randomType == 'public_checkout');
                if (check != undefined && check?.length > 0) {
                    return check[0]?.cntPostcode + check[0]?.cntStatecode;
                } else {
                    return 0;
                }
            },
        },
        { field: 'flagBlockSponsor', headerName: 'Block Sponsor', sortable: false, filter: false, suppressMovable: true },
        { field: 'flagBlockOrder', headerName: 'BlockOrder', sortable: false, filter: false, suppressMovable: true },
        { field: 'flagPoolQualified', headerName: 'PoolQualified', sortable: false, filter: false, suppressMovable: true },
        { field: 'onPoolStatus', headerName: 'Pool Status', sortable: false, filter: false, suppressMovable: true },
    ];

    public searchishColumnDefs: ColDef[] = [
        {
            field: 'randomDatetime',
            headerName: 'Date time',
            sortable: false,
            filter: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                let ramdomDatetime = params.data.upline?.filter((x: any) => x.aboNumber == this.historyRandomModel.forAboNumber);
                if (ramdomDatetime != undefined && ramdomDatetime.length > 0) {
                    return this.pipe.transform(ramdomDatetime[0].randomDateTime, 'dd/MM/yyyy HH:mm');
                } else {
                    return '';
                }
                // return this.pipe.transform(params.value, 'dd/MM/yyyy HH:mm')
            },
        },
        { field: 'randomType', headerName: 'Random Type', sortable: false, filter: false, suppressMovable: true },
        {
            field: 'randomFrom',
            headerName: 'Random by',
            sortable: false,
            filter: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                let randomFrom = params.data.upline?.filter((x: any) => x.aboNumber == this.historyRandomModel.forAboNumber);
                if (randomFrom != undefined && randomFrom.length > 0) {
                    return randomFrom[0].randomFrom;
                } else {
                    return '';
                }
            },
        },

        {
            field: 'upline[0].acceptStatus',
            headerName: 'Status',
            sortable: false,
            filter: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                let acceptStatus = params.data.upline?.filter((x: any) => x.aboNumber == this.historyRandomModel.forAboNumber);
                if (acceptStatus != undefined && acceptStatus.length > 0) {
                    return acceptStatus[0].acceptStatus;
                } else {
                    return '';
                }
            },
        },
        { field: 'forAboNumber', headerName: 'New ABO', sortable: false, filter: false, suppressMovable: true, width: 300 },
        { field: 'forOrderNumber', headerName: 'Order No', sortable: false, filter: false, suppressMovable: true },
        { field: 'randomId', headerName: 'Random Id', sortable: false, filter: false, suppressMovable: true, width: 350 },
    ];

    pageinfo: PageInfo = {
        page: 1,
        rowPerPage: 20,
        totalRecord: 0,
        allpage: 0,
    };

    constructor(
        private router: Router,
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private headerNavigateService: HeaderNavigateService,
        private pooldataService: PoolDataService,
        private retryrandomService: RetryRandomService
    ) {
        this.accessToken = userService.getAuthUser().access_token || '';
    }

    ngOnInit(): void {
        this.initialData();
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Pool Data', url: '/pooldata' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    search() {
        this.spinner.show();

        this.searchFormModel.page = this.pageinfo.page;
        this.searchFormModel.rowPerPage = this.pageinfo.rowPerPage;

        this.pooldataService.listPoolData(this.accessToken, this.searchFormModel).subscribe({
            next: (res: any) => {
                this.searchRowData = [];
                let response = res.data as Array<poolData>;
                // console.log(response);
                this.searchRowData.push(...response);
                this.searchGridApi.setRowData([]);
                this.searchGridApi.setRowData(this.searchRowData);
                this.resultRecord = 'Result ' + res.pageinfo.totalRecord + ' records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                this.resultText = '';
                if (res.pageinfo.totalRecord == 0) {
                    this.resultText = ': ไม่พบข้อมูล ';
                }

                this.pageinfo.totalRecord = res?.pageinfo?.totalRecord;
                this.pageinfo.allpage = Math.ceil(res?.pageinfo?.totalRecord / this.pageinfo.rowPerPage);

                this.spinner.hide();
            },
            error: (e: any) => {
                this.resultRecord = 'Result 0 records. Last update ' + this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                this.resultText = ': ไม่สามารถค้นหาข้อมูลได้ ' + e.error.error.errorCode;
                this.searchGridApi.setRowData(null);
                this.spinner.hide();
            },
            complete: () => {
                this.spinner.hide();
            },
        });
    }
    onSearchGridReady(params: any) {
        this.searchGridApi = params.api; // To access the grids API
    }

    onSearchHisGridReady(params: any) {
        this.searchHisGridApi = params.api; // To access the grids API
    }

    openHistoryRandom(param: any, postcode: any, province: any) {
        this.spinner.show();
        this.retryrandomService
            .listHistoryRandom(this.accessToken, {
                period: this.searchFormModel.period?.replace('-', ''),
                ABONumber: param,
                RandomType: this.searchFormModel.randomType,
                Status: '',
                page: 1,
                rowPerPage: 1000,
            })
            .subscribe({
                next: (res: any) => {
                    this.searcHishRowData = [];
                    let response = res.data as Array<HistoryRandom>;
                    // console.log(response);
                    this.searcHishRowData.push(...response);
                    this.searchHisGridApi.setRowData([]);
                    this.searchHisGridApi.setRowData(this.searcHishRowData);
                    // debugger;
                    this.historyRandomModel.postcode = postcode;
                    this.historyRandomModel.province = province;
                    this.historyRandomModel.forAboNumber = param;
                    $('#history_modal').modal('show');
                    this.spinner.hide();
                },
                error: (e: any) => {
                    this.searchHisGridApi.setRowData(null);
                    this.spinner.hide();
                },
                complete: () => {
                    this.spinner.hide();
                },
            });
    }

    clear() {
        this.searchFormModel.period = this.today.getFullYear().toString() + '-' + ('0' + (this.today.getMonth() + 1)).slice(-2);
        (this.searchFormModel.poolABO = ''),
            (this.searchFormModel.province = ''),
            (this.searchFormModel.postCode = ''),
            (this.searchFormModel.randomType = ['registration', 'public_checkout']),
            (this.searchFormModel.result = '');
        this.searchFormModel.resultDescription = '';
        this.searchGridApi.setRowData(null);
        this.resultRecord = '';
        this.resultText = '';
        this.ramdomTypeRegis = true;
        this.ramdomTypeCheckout = true;
    }

    onCheckboxTypeChange(name: any, param: any) {
        if (param.target.checked) {
            this.searchFormModel.randomType.push(name);
        } else {
            const index = this.searchFormModel.randomType.indexOf(name);
            if (index > -1) {
                this.searchFormModel.randomType.splice(index, 1);
            }
        }
        // console.log(this.searchFormModel.randomType);
    }

    /**
     * callback event for change page for paging
     * @param page
     */
    async gotoPage(page: number) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.pageinfo.page = page;
            this.search();
        }
    }

    /**
     * callback event for paging : change row per page
     * @param rowPerPage
     */
    changeRowPerpage(rowPerPage: number) {
        this.pageinfo.page = 1;
        this.pageinfo.rowPerPage = rowPerPage;
        this.search();
    }
}
