import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utility } from 'src/app/utility/utility';

@Component({
    selector: 'app-paging-tool',
    templateUrl: './paging-tool.component.html',
    styleUrls: ['./paging-tool.component.scss'],
})
export class PagingToolComponent implements OnInit {
    @Input() pageinfo: any = {
        page: 1,
        rowPerPage: 15,
        totalRecord: 0,
        allpage: 0,
        pagingSelectList: [],
    };
    pageNumber: any = [];
    rowPerPage: number = 15;
    @Output() onClickPage = new EventEmitter<any>();
    @Output() onClickRowPerPage = new EventEmitter<any>();

    pagingSelectList = [10, 15, 20, 50, 100];

    constructor() {}

    ngOnInit(): void {
        if (!Utility.isNull(this.pageinfo.pagingSelectList) && this.pageinfo.pagingSelectList.length > 0) {
            this.pagingSelectList = this.pageinfo.pagingSelectList;
        }
        this.rowPerPage = this.pageinfo.rowPerPage;
        this.getdisplayPage(this.pageinfo.page);
    }

    ngAfterViewInit(): void {}

    getdisplayPage(now: any) {
        let allPage: any = [],
            startPage: any = now - 4,
            endPage: any = now + 5;

        if (startPage < 0) {
            startPage = 1;
            endPage = 10;
        } else if (endPage > this.pageinfo.allpage) {
            startPage = this.pageinfo.allpage - 10;
            endPage = this.pageinfo.allpage;
        }

        for (let now = startPage; now <= endPage; now++) {
            if (now <= this.pageinfo.allpage && now > 0) {
                allPage.push(now);
            }
        }

        this.pageNumber = allPage;
        setTimeout(() => {
            this.getdisplayPage(this.pageinfo.page);
        }, 1000);
    }

    getShowingFrom() {
        let showingFrom: number = this.pageinfo.rowPerPage * this.pageinfo.page - (this.pageinfo.rowPerPage - 1);
        return showingFrom < this.pageinfo.totalRecord ? showingFrom : this.pageinfo.totalRecord;
    }

    getShowingTo() {
        let showingTo: number = this.pageinfo.page * this.pageinfo.rowPerPage;
        return showingTo < this.pageinfo.totalRecord ? showingTo : this.pageinfo.totalRecord;
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.onClickPage.emit(page);
            this.getdisplayPage(page);
        }
    }

    async skipPage() {}

    changeRowPerPage(event: any) {
        this.rowPerPage = parseInt(event.target.value);
        this.onClickRowPerPage.emit(this.rowPerPage);
    }
}
