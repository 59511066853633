import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'src/app/share.module';
import { PagingToolComponent } from './paging-tool/paging-tool.component';



@NgModule({
  declarations: [PagingToolComponent],
  imports: [
    CommonModule,
    ShareModule
  ],
  exports : [
    PagingToolComponent
  ]
})
export class ShareComponentModule { }
