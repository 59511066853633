import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PooldataComponent } from './pooldata.component';

import {
  AvatarModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  FormModule,
  GridModule,
  NavModule,
  ProgressModule,
  TableModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { ChartjsModule } from '@coreui/angular-chartjs';

import { WidgetsModule } from '../../views/widgets/widgets.module';
import { AgGridModule } from 'ag-grid-angular';
import { EditBtnCellRenderer } from '../components/edit-button-cell-renderer.component';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RandomBtnCellRenderer } from '../components/random-button-cell-renderer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonDirectiveModule } from '../../core/directive/CommonDirective.module';
import { ShareComponentModule } from '../share-component/share-component.module';

@NgModule({
  declarations: [PooldataComponent],
  imports: [
    CommonModule,
    CardModule,
    NavModule,
    IconModule,
    TabsModule,
    GridModule,
    ProgressModule,
    ReactiveFormsModule,
    ButtonModule,
    FormsModule,
    FormModule,
    ButtonModule,
    ButtonGroupModule,
    ChartjsModule,
    AvatarModule,
    TableModule,
    WidgetsModule,
    UtilitiesModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    CommonDirectiveModule,
    AgGridModule.withComponents([EditBtnCellRenderer, DateTimeRenderer, RandomBtnCellRenderer]),
    ShareComponentModule
  ],
})
export class PooldataModule { }