import { Injectable } from '@angular/core';
import { AuthUser, Permission, profileUser, User } from '../models/auth/user.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
//import { Utils } from "src/app/shared/utils";
import { LocalService } from './local.service';
import { DomSanitizer } from '@angular/platform-browser';
//import { resolve } from "dns";
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { AESEncryptDecrypt } from './AESEncryptDecrypt.service';
var format = require('date-format');
const AUTH_USER = 'abopoolbowebAuthUser';
//const AUTH_USER_ADMIN = "authUserAdmin";
const USER_INFO = 'abopoolbowebUserInfo';
const USER_PROFILE = 'abopoolbowebUserProfile';
const USER_PERMISSION = 'abopoolbowebPermission';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    //private hybrisLoginErrorSubject = new BehaviorSubject<AuthUser>(null);
    //currentHybrisLoginError = this.hybrisLoginErrorSubject.asObservable();

    private sessionTimeoutSubject = new BehaviorSubject<number>(3600);
    public currentSessionTimeout = this.sessionTimeoutSubject.asObservable();

    private production = environment.production;

    constructor(
        private http: HttpClient,
        private localService: LocalService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
        private aesService: AESEncryptDecrypt
    ) {}

    private formatErrors(error: any) {
        return throwError(error);
    }

    //getHybrisLoginErrorValue(): AuthUser {
    //  return this.hybrisLoginErrorSubject.value;
    //}

    //setHybrisLoginErrorValue(hybrisLoginError: AuthUser) {
    //  this.hybrisLoginErrorSubject.next(hybrisLoginError);
    //}

    setSessionTimeout(timeout: number) {
        this.sessionTimeoutSubject.next(timeout);
    }

    isLoggedIn() {
        return this.localService.getJsonValue(AUTH_USER);
    }

    logout() {
        this.destroyAuth();
        //this.router.navigate(['items'], { relativeTo: this.route });
        //this.router.navigateByUrl("/login");
        /*if (environment.admin_mode) {
      location.href = `${environment.admin_amwaycoth_url}`;
    } else {
      location.href = `${environment.amwaycoth}`;
    }*/
        this.router.navigate(['/login']);
    }

    setAuth(authUser: AuthUser) {
        this.setSessionTimeout(environment.session_timeout);
        // this.localService.setJsonValue(AUTH_USER, JSON.stringify(hybrisLogin));

        {
            this.localService.setJsonValue(AUTH_USER, JSON.stringify(authUser));

            let user: User = {
                email: authUser.username || '',
                token: authUser.access_token || '',
                username: authUser.username || '',
                name: '',
                login_date: new Date(),
            };
            this.setUser(user);

            /*
      let aboNum: {} = authUser.scope.split(" ");

      if (authUser.jwt_token) {
        let decodedJwt: Jwt = Utils.parseJwt(authUser.jwt_token);
        let userInfo = this.getUser();
        if (userInfo) {
          userInfo.partyId = decodedJwt.partyId;
          userInfo.username = decodedJwt.aboid;
          userInfo.name = decodedJwt.name;
          userInfo.token = authUser.access_token;
        } else {
          userInfo = {
            email: "",
            imagePath: "",
            login_date: new Date(),
            name: decodedJwt.name,
            partyId: decodedJwt.partyId,
            redirect_uri: "",
            token: authUser.access_token,
            username: decodedJwt.aboid,
          };
        }
        this.setUser(userInfo);
      } else {
        let user: User = {
          email: "",
          token: authUser.access_token,
          username: aboNum[0].substring(7),
          partyId: aboNum[2].substring(8),
          imagePath: "",
          login_date: new Date(),
          redirect_uri: "",
          name: "",
        };
        this.setUser(user);
      }
    */
        }
    }

    getAuthUser(): AuthUser {
        {
            return <AuthUser>JSON.parse(this.localService.getJsonValue(AUTH_USER));
        }
    }

    getUser(): User {
        return <User>JSON.parse(this.localService.getJsonValue(USER_INFO));
    }

    setUser(user: User) {
        this.localService.setJsonValue(USER_INFO, JSON.stringify(user));
    }

    setProfileUser(profileuser: profileUser) {
        this.localService.setJsonValue(USER_PROFILE, JSON.stringify(profileuser));
    }

    setPermission(permission: any) {
        this.localService.setJsonValue(USER_PERMISSION, JSON.stringify(permission));
    }

    getPermission(): any {
        return <any>JSON.parse(this.localService.getJsonValue(USER_PERMISSION));
    }

    getProfileUser(): profileUser {
        return <profileUser>JSON.parse(this.localService.getJsonValue(USER_PROFILE));
    }

    destroyAuth() {
        localStorage.clear();
        // if (environment.admin_mode) {
        //   this.localService.remove(AUTH_USER_ADMIN);
        // } else {
        //   this.localService.remove(AUTH_USER);
        // }

        // this.localService.remove(USER_INFO);
        // this.localService.remove('reportConfig');
    }

    getjwtToken() {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');

        const body = {
            grantType: environment.grantType,
            clientId: environment.client_id,
            clientSecret: environment.clientSecret,
        };

        return this.http
            .post(`${environment.api_url}/webauthorize/token`, body, {
                headers: headers,
            })
            .pipe(catchError(this.formatErrors));
    }

    login(username: string, password: string) {
        let expireTimestamp = format(format.ISO8601_WITH_TZ_OFFSET_FORMAT, new Date(new Date().getTime() + 3 * 60 * 1000));

        const requestObj = {
            uName: username,
            uPass: password,
            expireTimestamp: expireTimestamp,
        };

        const plainText = JSON.stringify(requestObj);
        const encryptText = this.aesService.aesEncrypt(plainText);
        const hash = this.aesService.hashGenerate(encryptText);
        let token = this.getAuthUser().access_token || '';
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);

        const body = {
            encData: encryptText,
            hashData: hash,
            expireTimestamp: expireTimestamp,
        };

        return this.http.post(`${environment.api_url}/apibo/call/login`, body, { headers }).pipe(catchError(this.formatErrors));

        // return new Observable((observer) => {
        //   if (username == 'admin' && password == '1234') {
        //     let response: LoginResponseModel = { token: '12345678' };
        //     observer.next(response);
        //   } else {
        //     observer.error('Invalid Login');
        //   }
        // });
    }
    /*
  getToken() {
    let user = this.getUser();
    let abonum = user.username;
    let partyId = user.partyId;

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    const body = new URLSearchParams();
    body.set("client_id", environment.client_id);
    body.set("client_secret", environment.client_secret);
    body.set("grant_type", "client_credentials");
    body.set("scope", `salesPlanAff=200 aboNum=${abonum} partyId=${partyId}`);
    return this.http
      .post(
        `${environment.mashery_api_url}/rest/oauth2/v1/token`,
        body.toString(),
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }
  */

    exchangeToken(idToken: string) {
        const body = new HttpParams().set('idToken', idToken);
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(`${environment.api_url}/webauthorize/token`, body.toString(), { headers });
    }
}
