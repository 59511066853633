import { formatNumber } from "@angular/common";

var format = require('date-format');
export class Utility{

    static isNullOrEmpty(value?:string|null){
        if(value == undefined || value == "" || value == null){
            return true;
        }else{
            return false;
        }
    }

    static isNull(value?:any){
        if(value == undefined || value == null){
            return true;
        }else{
            return false;
        }
    }

    static isString(value:any){
        return typeof value == "string";
    }

    static isNumber(value:any){
        return typeof value == "number";
    }

    static isBoolean(value:any){
        return typeof value == "boolean";
    }

    // for check date
    static isObject(value:any){
        return typeof value == "object";
    }

    static isValidNumber(value:any){
        return !isNaN(value)
    }

    static isValidDate(value:any) {

        return value.length >= 8 && !isNaN(Date.parse(value));
        //return value instanceof Date && !isNaN(value);
    }

    static formatDateTimeGmt(date:Date|null|undefined,isStartTime:boolean | undefined){
        if(date == null || date == undefined){
            return "";
        }
        let formatDate = new Date(date.getTime());
        if(isStartTime != undefined){
            if(isStartTime == true){
                formatDate.setHours(0);
                formatDate.setMinutes(0);
                formatDate.setSeconds(0);
            }else{
                formatDate.setHours(23);
                formatDate.setMinutes(59);
                formatDate.setSeconds(59);
            }
        }
        let dataTimeGmtFormat = format(format.ISO8601_WITH_TZ_OFFSET_FORMAT, formatDate);
        return dataTimeGmtFormat;
    }

    //doesnt set time to 00:00 -24:59:59
    static formatDateAndTimeGmt(date:Date|null|undefined){
        if(date == null || date == undefined){
            return "";
        }
        let formatDate = new Date(date);

        let dataTimeGmtFormat = format(format.ISO8601_WITH_TZ_OFFSET_FORMAT, formatDate);
        return dataTimeGmtFormat;
    }

    static formatDateTimeGmtWithDefault(date:Date|null|undefined,defaultValue:any){
        if(date == null || date == undefined){
            return defaultValue;
        }
        let dataTimeGmtFormat = format(format.ISO8601_WITH_TZ_OFFSET_FORMAT, date);
        return dataTimeGmtFormat;
    }

    static formatDateTime(date:Date|null|undefined){
        if(date == null || date == undefined){
            return "";
        }
        let dataTimeGmtFormat = format( date);
        return dataTimeGmtFormat;
    }

    static formatDateYYYYMMDD(dateInput:Date){
        // Date object initialized as per New Zealand timezone. Returns a datetime string
        let nz_date_string = dateInput.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });

        // Date object initialized from the above datetime string
        let date_nz = new Date(nz_date_string);

        // year as (YYYY) format
        let year = date_nz.getFullYear();

        // month as (MM) format
        let month = ("0" + (date_nz.getMonth() + 1)).slice(-2);

        // date as (DD) format
        let date = ("0" + date_nz.getDate()).slice(-2);

        // hours as (HH) format
        let hours = ("0" + date_nz.getHours()).slice(-2);

        // minutes as (mm) format
        let minutes = ("0" + date_nz.getMinutes()).slice(-2);

        // seconds as (ss) format
        let seconds = ("0" + date_nz.getSeconds()).slice(-2);

        // date as YYYY-MM-DD format
        let date_yyyy_mm_dd = year + "-" + month + "-" + date;
        //console.log("Date in YYYY-MM-DD format: " + date_yyyy_mm_dd);

        // time as hh:mm:ss format
        //let time_hh_mm_ss = hours + ":" + minutes + ":" + seconds;
        //console.log("Time in hh:mm:ss format: " + time_hh_mm_ss);

        // date and time as YYYY-MM-DD hh:mm:ss format
        //let date_time = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;
        //console.log("Date and Time in YYYY-MM-DDThh:mm:ss format: " + date_time);
        //return format("yyyy-MM-ddThh:mm:ss", new Date(dateStr));
        return date_yyyy_mm_dd;

    }


    /**

        const ids = Util.distinct(array, 'id');
        console.log(ids)
    */
    static distinct(array:Array<any>, field:string){
        if(array == undefined || array.length == 0){
            return [];
        }
        return array.reduce((accumulator, current) => {
            if(!Utility.isNullOrEmpty(current[field]) && !accumulator.includes(current[field])) {
                accumulator.push(current[field])
            }
            return accumulator;
            }, []
        )
    }

    static getErrorCode(error:any){
        return error?.error?.errorCode || "500";
    }

    static getErrorMessage(error:any){
        return error?.error?.errorMessage || "System Error";
    }

    static formatNum(num:number){
        return formatNumber(Number(num), 'en-US', '1.0-0')
    }
}
