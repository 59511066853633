<c-container> 
            <form cForm>
                <c-row class="mb-4"> 
                    <c-col [offset]="3" [sm]="6">  
                                <c-col    style="position: relative;"> 
                                    <input
                                        class="input-hide"
                                        id="inputFileImport"
                                        name="nameInputFileImport"
                                        (change)="changeSelectedFile($event)"
                                        type="file"
                                        [accept]="allowedTypeFile" 
                                        
                                    />
                                    <div class="input-group">
                    
                                        <input
                                            id="inputName"
                                            name="inputName"
                                            [(ngModel)]="fileName"
                                            style="background-color:white"
                                            type="text"
                                            class="form-control" 
                                            (click)="clickBrowesFileBtn('inputFileImport')"
                                            readonly
                                            placeholder="Choose File"
                                        />  
                                        <button (click)="clickBrowesFileBtn('inputFileImport')" class="btn btn-primary fw-bolder" type="button" >
                                            Browse
                                        </button>
                                    </div>
                                </c-col> 
                    </c-col>
                </c-row>
               
                <c-row class="mb-4">
                    <c-col [offset]="4" [sm]="4">
                        <c-row>
                            <c-col>
                                <button cButton color="success"  class="w-100 text-white" (click)="upLoadFile()">
                                    Upload
                                </button>
                            </c-col>
                            <c-col>
                                <button cButton color="light" class="w-100" (click)="onClickClear()" >
                                    Clear
                                </button>
                            </c-col>
                        </c-row>
                    </c-col>
                </c-row>
            </form> 
</c-container>

<c-container>
    <c-row *ngIf="resultRecord">
        <c-col [xl]="4">
            <span class="badge bg-info fs-5">{{ resultRecord }}</span> <br><br>
            <span class="badge bg-success fs-5 ms-5">Status : {{ resultImport?.importStatus }}</span> <br><br>
            <span class="badge bg-success fs-5 ms-5">Records Total : {{ resultImport?.recordsTotal }}</span> <br><br>
            <span class="badge bg-info fs-5 ms-5">Records Success : {{ resultImport?.recordsSuccess }}</span> <br><br>
            <span class="badge bg-danger fs-5 ms-5">Records Fail : {{ resultImport?.recordsFail }}</span> <br><br>
            <span *ngIf="resultImport?.messageImportFile" class="badge bg-danger fs-5 ms-5">Import File S3 : {{ resultImport?.messageImportFile }}</span> <br><br>
        </c-col>  
    </c-row>  
</c-container>

 

<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p class="text-white">Importing...</p>
</ngx-spinner>