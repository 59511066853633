import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LoginModule } from './modules/login/login.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';

// Import containers
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './containers';

import {
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
} from '@coreui/angular';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { CampaignroleModule } from './modules/campaignrole/campaignrole.module';
import { DemoroleModule } from './modules/demorole/demorole.module';
import { NewRandomModule } from './modules/newrandom/newrandom.module';
import { RetryrandomModule } from './modules/retryrandom/retryrandom.module';
import { HistoryrandomModule } from './modules/historyrandom/historyrandom.module';
import { PooldataModule } from './modules/pooldata/pooldata.module';
import { ApilogModule } from './modules/apilog/apilog.module';
import { ConfigModule } from './modules/config/config.module';
import { ShareModule } from './share.module';
import { ImportpooldataModule } from './modules/importpooldata/importpooldata.module';
import { environment } from 'src/environments/environment';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { LogoutModule } from './modules/logout/logout.module';

const oktaAuth = new OktaAuth({
    issuer: environment.okta_issuer,
    clientId: environment.okta_client_id,
    redirectUri: environment.okta_redirect_uri,
    scopes: ['openid', 'profile', 'email'],
});

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent];

@NgModule({
    declarations: [AppComponent, ...APP_CONTAINERS],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AvatarModule,
        BreadcrumbModule,
        FooterModule,
        DropdownModule,
        GridModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        PerfectScrollbarModule,
        NavModule,
        ButtonModule,
        FormModule,
        UtilitiesModule,
        ButtonGroupModule,
        ReactiveFormsModule,
        SidebarModule,
        SharedModule,
        TabsModule,
        ListGroupModule,
        ProgressModule,
        BadgeModule,
        ListGroupModule,
        CardModule,
        DashboardModule,
        DemoroleModule,
        NewRandomModule,
        RetryrandomModule,
        HistoryrandomModule,
        PooldataModule,
        HttpClientModule,
        LoginModule,
        CampaignroleModule,
        ApilogModule,
        NgxSpinnerModule,
        AgGridModule.withComponents([]),
        ConfigModule,
        ShareModule,
        ImportpooldataModule,
        OktaAuthModule,
        LogoutModule,
    ],
    providers: [
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        IconSetService,
        Title,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
