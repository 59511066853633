import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
export interface ImportFileModel {
  fileBase64: string
  fileName: string
  fileSize: number
  fileType: string
}
@Injectable({
  providedIn: 'root'
})
export class ImportpooldataService {

  constructor(
    private http: HttpClient,
    private userService: UserService) { }

  private getHeader() {
    const token = this.userService.getAuthUser()?.access_token || '';
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`);
    return headers;
}



updateImportPoolData(body : ImportFileModel) {
  const headers = this.getHeader();  
  return this.http.post(`${environment.api_url}/apibo/call/importpooldata`, body, { headers });
}
}
