// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    session_timeout: 7200,
    //api_url: 'https://ggsjjbrprc.execute-api.ap-southeast-1.amazonaws.com/dev',
    api_url: 'https://dev.abopool.amway.co.th',
    client_id: 'bo202204',
    grantType: 'client_credentials',
    clientSecret: '3Ck2JaEaybcibGTQ',
    okta_client_id: '0oa1qinnehzPFYbSr0h8',
    okta_issuer: 'https://amway.okta.com/oauth2/default',
    okta_redirect_uri: 'https://dev.abopool.amway.co.th/login/callback',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
