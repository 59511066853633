<c-container>
  <c-row>
      <c-col class="col-12 col-sm-9 col-md-5 mx-auto">
          <form cForm>
              <c-row class="mb-4">
                  <label class="col-4 text-end" cCol cLabel="col" for="search_period">
                      Period :
                  </label>
                  <c-col>
                      <input class="form-control" type="month" aria-label="search_period" data-date-format="yyyyMM"
                          id="search_period" name="search_period" [(ngModel)]="searchFormModel.period" (change)="changePeriod($event)">
                  </c-col>
              </c-row>

              <c-row class="mb-4">
                  <label class="col-4 text-end" cCol cLabel="col" for="search_date_from">
                      Date :
                  </label>
                  <c-col class="col-3">
                    <select name="search_date_from" class="form-select" aria-label="search_date_from"
                    [(ngModel)]="searchFormModel.dateFrom" cSelect>
                        <option *ngFor="let option of dateList" value="{{ option }}">
                            {{ option }}
                        </option>
                    </select>
                  </c-col>
                  <label class="col text-end" cCol cLabel="col" for="search_date_to">
                    To :
                  </label>
                  <c-col class="col-3">
                    <select name="search_date_to" class="form-select" aria-label="search_date_to"
                    [(ngModel)]="searchFormModel.dateTo" cSelect>
                        <option *ngFor="let option of dateList" value="{{ option }}">
                            {{ option }}
                        </option>
                    </select>
                  </c-col>
              </c-row>

              <c-row class="mb-4">
                  <label class="col-4 text-end" cCol cLabel="col" for="search_client_id">
                      Client ID :
                  </label>
                  <c-col>
                    <input aria-label="search_client_id" type="text" name="search_client_id" id="search_client_id"
                            [(ngModel)]="searchFormModel.clientId" cFormControl/>
                  </c-col>
              </c-row>

              <c-row class="mb-4">
                  <label class="col-4 text-end" cCol cLabel="col" for="search_api_path">
                      API Path :
                  </label>
                  <c-col>
                    <input aria-label="search_api_path" type="text" name="search_api_path" id="search_api_path"
                        [(ngModel)]="searchFormModel.apiPath" cFormControl/>
                  </c-col>
              </c-row>

              <c-row class="mb-4">
                <label class="col-4 text-end" cCol cLabel="col" for="search_request_id">
                    Request ID :
                </label>
                <c-col>
                    <input aria-label="search_request_id" type="text" name="search_request_id" id="search_request_id"
                    [(ngModel)]="searchFormModel.requestId" cFormControl/>
                </c-col>
              </c-row>

              <c-row class="mb-4">
                  <label class="col-4 text-end" cCol cLabel for="inputEmail-1">Status :</label>
                  <c-col>
                      <c-form-check>
                          <input cFormCheckInput id="search_type_success" name="search_type_success" value="success" type="checkbox" (change)="onCheckboxTypeChange($event)" checked/>
                          <label cFormCheckLabel for="search_type_success">Success</label>
                      </c-form-check>
                  </c-col>
                  <c-col>
                      <c-form-check>
                          <input cFormCheckInput id="search_type_fail" name="search_type_fail" value="failed" type="checkbox" (change)="onCheckboxTypeChange($event)" checked />
                          <label cFormCheckLabel for="search_type_fail">Fail</label>
                      </c-form-check>
                  </c-col>
              </c-row>

              <c-row class="mb-4">
                  <c-col [offset]="4">
                      <c-row>
                          <c-col>
                              <button cButton color="primary" (click)="pageinfo.page = 1;search()" class="w-100 text-white">
                                  Search
                              </button>
                          </c-col>
                          <c-col>
                              <button cButton color="light" (click)="clear()" class="w-100">
                                  Clear
                              </button>
                          </c-col>
                      </c-row>
                  </c-col>
              </c-row>
          </form>
      </c-col>
  </c-row>
</c-container>

<c-container>
    <c-row>
        <c-col>
            <span class="badge bg-info fs-5">{{ resultRecord }}</span>
            &nbsp;
            &nbsp;
            <span class="badge bg-warning fs-5">{{ resultText }}</span>
            <br>
            <br>
        </c-col>
    </c-row>
    <c-row>
        <c-col>
            <ag-grid-angular style="width: 100%; height: 50vh" class="ag-theme-alpine" [rowData]="searchRowData"
                [columnDefs]="searchColumnDefs" (gridReady)="onSearchGridReady($event)" [pagination]="false"
                [paginationPageSize]="pageinfo.rowPerPage" [columnHoverHighlight]="false"
                [enableCellTextSelection]="true" [ensureDomOrder]="true">
            </ag-grid-angular>
            <app-paging-tool [pageinfo]="pageinfo" (onClickPage)="gotoPage($event)"
             (onClickRowPerPage)="changeRowPerpage($event)">
            </app-paging-tool>
        </c-col>
    </c-row>
    <!-- <c-row class="mb-3 mt-3">
      <h6>**Show top 100 records</h6>
    </c-row> -->
</c-container>

<div class="modal fade" id="detail_modal" tabindex="-1" aria-labelledby="Detail Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="word-break: break-all;">
                <div class="table_data_modal" aria-hidden="true">
                    <c-row [gutter]="{gy: 3}">
                        <c-col class="col-3">
                          <h6>Request ID : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.requestId}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>UID : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.uId}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Lambda Name : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.lambdaName}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>API Path : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.apiPath}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Client ID : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.clientId}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Request User : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.requestUser}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Request Datetime : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.requestDateTime}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Response Datetime : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.responseDateTime}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Status : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.status}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Error : </h6>
                        </c-col>
                        <c-col class="col-9">
                          {{apiLogModalData.errorMessage}}
                        </c-col>
                        <c-col class="col-3">
                          <h6>Request Body : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.requestBody}}
                       </c-col>
                        <c-col class="col-3">
                          <h6>Response body : </h6>
                        </c-col>
                        <c-col class="col-9">
                           {{apiLogModalData.responseBody}}
                        </c-col>
                      </c-row>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Close
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p class="text-white">Loading...</p>
</ngx-spinner>

